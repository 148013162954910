import { NavLink } from "react-router-dom";
import { Squeeze as Hamburger } from "hamburger-react";
import logo from "../images/logo.png";

import "./nav.css";
import { useState } from "react";

const Navbar = () => {
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const [mobileNav, setMobileNav] = useState(false);

  const toggleMobileNav = () => {
    setMobileNav(!mobileNav);
  };

  return (
    <nav className={color ? "nav-scroll" : ""}>
      <div className="navigation">
        <NavLink to="/">
          <img src={logo} alt="Xhub Solutions Logo" className="logo" />
        </NavLink>
        <div className={mobileNav ? "nav-links open" : "nav-links"}>
          <NavLink
            to="/"
            style={({ isActive }) => ({
              color: isActive ? "#8A3554" : "#3c3c3c",
              fontWeight: isActive ? "bold" : "normal",
            })}
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            style={({ isActive }) => ({
              color: isActive ? "#8A3554" : "#3c3c3c",
              fontWeight: isActive ? "bold" : "normal",
            })}
          >
            About
          </NavLink>
          <NavLink
            to="/products"
            style={({ isActive }) => ({
              color: isActive ? "#8A3554" : "#3c3c3c",
              fontWeight: isActive ? "bold" : "normal",
            })}
          >
            Products
          </NavLink>
          <NavLink
            to="/price"
            style={({ isActive }) => ({
              color: isActive ? "#8A3554" : "#3c3c3c",
              fontWeight: isActive ? "bold" : "normal",
            })}
          >
            Price
          </NavLink>
          <button className="btn primary-btn">Contact us</button>
        </div>
        <div className="toggle-nav-btn" onClick={toggleMobileNav}>
          <Hamburger size={20} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
