import "./landing.css";

const Home = () => {
  return (
    <div className="flex items-center justify-center h-screen p-100 bg-gradient-to-tl from-[#ffffff] to-[#fff6ff]">
      <div className="text-center">
        <h1 className="title-text font-extrabold w-full m-auto">
          Our Website is currently under Construction.
        </h1>
        <p className="pt-5">It will be available in a short while.</p>
        <p className="pt-5">
          You can contact us at{" "}
          <a
            href="mailto:office@xhubtechnologies.com"
            className="text-[#8A3554] "
          >
            office@xhubtechnologies.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Home;
