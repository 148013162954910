import { Link } from "react-router-dom";
import { FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <>
      <div className="p-5 md:p-20">
        <div className="flex flex-col md:flex-row items-start justify-between gap-10">
          <div className="w-full md:w-[20%]">
            <p>
              12b Ogunnaike Street, Admiralty way, Lekki Phase 1, Lagos Nigeria.
            </p>
            <div className="flex items-center justify-start py-5 gap-5">
              <a href="https://instagram.com/xhub_technologies">
                <FaInstagram className="text-3xl" />
              </a>
              <a href="https://instagram.com/xhub_technologies">
                <FaTwitter className="text-3xl" />
              </a>

              <a href="https://linkedin.com/xhub_technologies">
                <FaLinkedin className="text-3xl" />
              </a>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <h1 className="text-xl font-bold pb-5">Products</h1>
            <ul className="flex flex-col gap-4">
              <a href="https://xhubtechnologies.com">
                <li>Mymarketng</li>
              </a>
              <a href="https://xhubtechnologies.com">
                <li>Babr.</li>
              </a>
              <a href="https://xhubtechnologies.com">
                <li>Smartclass</li>
              </a>
              <a href="https://xhubtechnologies.com">
                <li>Chopdaily</li>
              </a>
            </ul>
          </div>
          <div className="flex flex-col items-start">
            <h1 className="text-xl font-bold pb-5">Company</h1>
            <ul className="flex flex-col gap-4">
              <a href="https://xhubtechnologies.com">
                <li>About</li>
              </a>
              <a href="https://xhubtechnologies.com">
                <li>Blog</li>
              </a>
              <a href="https://xhubtechnologies.com">
                <li>The Team</li>
              </a>
            </ul>
          </div>
          <div className="flex flex-col items-start">
            <h1 className="text-xl font-bold pb-5">Legal</h1>
            <ul className="flex flex-col gap-4">
              <a href="https://xhubtechnologies.com">
                <li>Terms & Conditions</li>
              </a>
              <a href="https://xhubtechnologies.com">
                <li>Privacy Policies</li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#c3c3c3] px-5 md:px-20 p-5 flex items-center justify-between">
        <p className="text-[#545454] text-sm font-semibold">
          Copyrights {currentYear}. Xhub Solutions Ltd.
        </p>
        <p className="text-[#545454] text-sm font-semibold">
          All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
