const Products = () => {
  return (
    <div>
      <h1>Products</h1>
      <p>This is the products page</p>
    </div>
  );
};

export default Products;
