import { Link } from "react-router-dom";

import notFoundImage from "../images/error_page.jpg";

import "./404.css";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen text-center">
      <div className="text-center w-full">
        <img
          src={notFoundImage}
          className="m-auto"
          alt="not found"
          width={300}
        />
        <h2 className="text-3xl font-bold">Oops...</h2>
        <p className="text-xl p-3">
          We don't know where you are headed, but you can click on the link
          below to find your way back.
        </p>
        <Link to="/" className="underline text-[#8A3554] font-semibold">
          Back to the homepage...
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
