import "./App.css";

import { Routes, Route } from "react-router-dom";
import About from "./routes/about";
import Landing from "./routes/landing";
import Products from "./routes/products";
import NotFound from "./routes/404";

function App() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Landing />} />
      <Route path="/about" element={<About />} />
      <Route path="/products" element={<Products />} />
    </Routes>
  );
}

export default App;
